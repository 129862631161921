import PropTypes from 'prop-types';

import { useState } from 'react';
import profile from 'src/store/profile';
// @mui
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

CreateFeedback.propTypes = {
  success: PropTypes.func.isRequired,
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CreateFeedback({ student, success, file }) {
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [files, setFiles] = useState(false);

  const handleClick = () => {
    const formData = new FormData();
    formData.append('text', text);
    formData.append('teacher_id', profile.data.id);
    formData.append('student_id', student.id);
    formData.append('email', student.email);
    formData.append('course_id', 1);
    if (files) {
      formData.append('pdf', files);
    }

    if (text !== '')
      fetch('https://admin.lalaenglish.com.ua/index.php/feedback-api/create', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.id) success();
            else if (result.status === 'error') setError(result.message);
            console.log(result);
          },
          (error) => {
            console.log(error);
          }
        );
    else setError('true');
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          multiline
          rows={5}
          name="text"
          label="Введіть текст"
          value={text}
          error={Boolean(error)}
          onChange={(event) => {
            setText(event.target.value);
          }}
        />

        <Button component="label" role={undefined} variant="outlined" tabIndex={-1}>
          Додати PDF
          <VisuallyHiddenInput type="file" onChange={(event) => setFiles(event.target.files[0])} />
        </Button>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Додати
        </LoadingButton>
      </Stack>
    </>
  );
}
